.rmsc {
  --rmsc-main: #4db96b !important;
  --rmsc-hover: #f1f3f5 !important;
  --rmsc-selected: #e2e2e2 !important;
  --rmsc-border: #dddddd !important;
  --rmsc-gray: #aaa !important;
  --rmsc-bg: #ffffff !important;
  --rmsc-p: 10px !important; /* Spacing */
  --rmsc-radius: 8px !important; /* Radius */
  --rmsc-h: 44px !important;
  font-size: 18px !important;
  line-height: 21.6px !important;
  font-weight: 300 !important;
  text-align: left !important;
  width: 100% !important;
}

input[type='checkbox'] {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px !important;
  height: 20px !important;
  appearance: none;
  border-radius: 4px;
  border: 1px solid #9b9b9b;
  box-shadow: none;
  padding-bottom: 3px;
}

input[type='checkbox']:checked {
  background-color: #4db96b;
  border: 1px solid #4db96b;
}

input[type='checkbox']:checked:after {
  content: url('assets/icons/tick.svg');
  color: white;
}

.dropdown-heading-value span {
  font-family: 'ICONIQ Quadraat';
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: 120%;
}

@media (prefers-color-scheme: dark) {
  .rmsc {
    --rmsc-main: #4db96b !important;
    --rmsc-hover: #575757 !important;
    --rmsc-selected: #2c2c2c !important;
    --rmsc-border: #626262 !important;
    --rmsc-gray: #fff !important;
    --rmsc-bg: #2a2a2a !important;
  }

  .panel-content {
    background-color: #151515 !important;
  }
}
