@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Iconiq Quadraat';
  src: url('assets/fonts/Quadraat-Light-ICONIQ.ttf');
}

body {
  margin: 0;
  font-family: 'Iconiq Quadraat';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

input {
  background-color: transparent;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

.hide-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.osano-cm-widget {
  display: none !important;
}

.tab_list button[data-state='active'] {
  position: relative;
  &:before {
    content: ' ';
    position: absolute;
    width: 20px;
    height: 20px;
    background: radial-gradient(circle at top left, transparent 71%, white 71%);
    bottom: 0;
    left: -20px;
    z-index: 1;
  }
  &:after {
    content: ' ';
    position: absolute;
    width: 20px;
    height: 20px;
    background: radial-gradient(
      circle at top right,
      transparent 71%,
      white 71%
    );
    bottom: 0;
    right: -20px;
    z-index: 1;
  }
}

/* // svg method if above has issues */
/*
.tab_list button[data-state="active"] {
  position: relative;
  &:before {
    content: url("data:image/svg+xml, %3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg' className='absolute left-%5B-20px%5D bottom-0 z-20' %3E%3Cpath d='M20 4.45842e-05C20 11.0457 11.0457 20 0 20H20V4.45842e-05Z' fill='white' /%3E%3C/svg%3E");
    position: absolute;
    left: -20px;
    bottom: -4px;
  }
  &:after {
    content: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg' className='absolute right-%5B-20px%5D bottom-0 z-20'%3E%3Cpath d='M0 4.45842e-05C0 11.0457 8.9543 20 20 20H0V4.45842e-05Z' fill='white' /%3E%3C/svg%3E");
    position: absolute;
    right: -20px;
    bottom: -4px;
  }   
}
*/

.section_companies-disclaimer {
  z-index: 20;
  border-top: 1px solid #5c5c5c;
  background-color: #fff;
  width: 100%;
  transition: opacity 0.2s;
  position: fixed;
  bottom: 0%;
  transition: transform 400ms ease-out;
  animation: slideOut 1s ease-in-out forwards;
  animation-delay: 15s; /* Delays the start of the animation */
}

input:-internal-autofill-selected {
  appearance: none !important;
  background-color: inherit !important;
  color: inherit !important;
  background-image: none !important;
}

input:-webkit-autofill {
  appearance: none !important;
  background-color: inherit !important;
  color: inherit !important;
  background-image: none !important;
  box-shadow: 0 0 0px 1000px #ffffff inset !important;
  -webkit-text-fill-color: #000000 !important;
  transition: background-color 5000s ease-in-out 0s;
}

@keyframes slideOut {
  0% {
    transform: translateY(0%);
  }
  100% {
    transform: translateY(100%);
  }
}

@media (prefers-color-scheme: dark) {
  .section_companies-disclaimer {
    border-color: #fff;
    background-color: #151515;
    color: #fff;
  }
  input:-webkit-autofill {
    box-shadow: 0 0 0px 1000px #151515 inset !important;
    -webkit-text-fill-color: #fff !important;
  }

  html {
    color-scheme: dark;
  }
}
